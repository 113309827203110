import {GlobalStyles} from "@mui/material";
import {PRIMARY_FONT, theme} from "./index";
import React from "react";

export default function Styles() {
    return <GlobalStyles styles={{
        '*': {
            boxSizing: 'border-box',
            fontFamily: PRIMARY_FONT,
            color: theme.palette.text.primary,
        },
        'body, #root': {
            margin: 0,
            width: '100vw',
            height: '100vh',
            display: 'flex',
            fontFamily: PRIMARY_FONT,
            color: theme.palette.text.primary,
            fontSize: 14,
            overflow: 'hidden'
        }
    }}/>
}