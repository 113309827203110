import {styled} from "@mui/material/styles";

export const Form = styled('form')(( { theme } ) => ({
    background: theme.palette.background.paper,
    width: 'fit-content',
    borderRadius: '1rem',
    padding: '1.5rem',
    [theme.breakpoints.down('md')]: {
        margin: '.5rem'
    },
    [theme.breakpoints.up('md')]: {
        maxWidth: '1000px'
    }
}));