import React from "react";
import {
    Box,
    createTheme, IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    ThemeProvider
} from "@mui/material";
import Nimbey from "../static/img/sideHeadLogo.svg"
import {
    AdminPanelSettings,
    HomeOutlined,
    HubOutlined, InfoOutlined, LogoutOutlined,
    Menu,
    SettingsOutlined,
    UploadOutlined
} from "@mui/icons-material";
import axios from "axios";
import {useNavigate} from "react-router-dom";


const dashTheme = createTheme({
    typography: {fontSize: 14, fontFamily: "Public Sans, sans-serif"},
    palette: {
        text: {
            primary: '#161C24',
            secondary: '#FFF'
        },
        background: {
            default: '#161C24',
            active: "#C1FF72"
        }
    },
    components: {
        MuiListItemIcon: {
            styleOverrides: {
                root: {fontSize: "24px"}
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {fontWeight: "400", fontSize: "14px"}
            }
        },
        MuiListItemText: {
            styleOverrides: {
                root: {color: "#FFF"}
            }
        }
    }
})

const SideItem = ({text, icon, textStyle = {}, path='', onClick}) => {
    let active = Boolean(window.location.pathname.match(`^${path.replace('/', '\/')}\/?$`));

    return <ListItem sx={{p: 0}}>
        <ListItemButton onClick={onClick} className={active ? 'Mui-selected' : null} aria-describedby={text} href={path} sx={(theme) => ({
            px: 3, py: 1.5,
            '& .MuiTypography-root, & .MuiSvgIcon-root': {
                color: theme.palette.text.secondary,
                fill: theme.palette.text.secondary
            },
            '&.Mui-selected': {
                background: theme.palette.background.active,
                "& .MuiTypography-root, & .MuiSvgIcon-root": {
                    color: theme.palette.text.primary,
                    fill: theme.palette.text.primary
                }
            },
            '&:hover': {
                background: '#1976D21E',
                '& .MuiTypography-root, & .MuiSvgIcon-root': {
                    color: theme.palette.text.secondary,
                    fill: theme.palette.text.secondary
                }
            }

        })}>
            <ListItemIcon sx={{mr: 1.5, minWidth: 'fit-content'}}>
                {icon}
            </ListItemIcon>
            <ListItemText primary={text}/>
        </ListItemButton>
    </ListItem>
}


export default function Sidebar(props={}) {

    const navigate = useNavigate();

    const handleLogout = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/api/users/logout`, {}).then((res) => {
            if(res.status === 200) {
                navigate("/login");
            }
        }).catch((err) => {console.log(`Error to logout ${err}`);alert(err);})
    }

    return (
        <>
            <ThemeProvider theme={dashTheme}>
                <Stack id={"sideBar"} className={'toggleSide'} sx={(theme) => ({
                    height: '100%',
                    width: {xs: '100%', sm: '250px'},
                    backgroundColor: theme.palette.background.default,
                    overflow: 'hidden',
                    zIndex: '100',
                    position: {xs: "fixed", sm: "relative"},
                    "&.toggleSide": {
                        display: {xs: 'none', md: 'flex'},
                    }
                })}>

                    <Box sx={{px: 3, py: 2, mb: 10, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                        <img src={Nimbey} alt={"nimbey-logo"} style={{maxHeight: "36px"}}/>
                        <IconButton onClick={() => document.getElementById("sideBar").classList.toggle('toggleSide')}  sx={{display: {md: 'none'}}}>
                            <Menu sx={{fontSize: 24, fill: '#FFF'}} />
                        </IconButton>
                    </Box>

                    <Stack sx={{width: '100%', height: '100%', overflowY: 'auto'}} justifyContent={"space-between"}>
                        <List>
                            <SideItem path={"/dashboard"} text={"Home"} icon={<HomeOutlined/>}/>
                            { props.user && props.user.admin ? <SideItem path={"/dashboard/contract-upload"} text={"Contract Upload"} icon={<UploadOutlined/>}/> : <></>}
                            <SideItem path={"/dashboard/products"} text={"Products"} icon={<HubOutlined/>}/>
                            { props.user && props.user.admin ? <SideItem path={"/dashboard/admin/orders"} text={"Admin"} icon={<AdminPanelSettings/>}/> : <></>}
                            <SideItem path={"/dashboard/settings"} text={"Settings"} icon={<SettingsOutlined/>}/>
                        </List>
                        <List>
                            <SideItem path={"/dashboard/help"} text={"Help"} icon={<InfoOutlined/>}/>
                            <SideItem onClick={handleLogout} text={"Logout"} icon={<LogoutOutlined/>}/>
                        </List>
                    </Stack>

                </Stack>
            </ThemeProvider>
        </>
    )
}