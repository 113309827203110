import {Box, Button, CircularProgress, Stack, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import axios from "axios";
import {useOutletContext, useParams, useSearchParams} from "react-router-dom";
import {CheckCircleOutlined} from "@mui/icons-material";
import FallbackImage from "../../static/img/fallback.png";


export default function OrderRequest() {

    const [order, setOrder] = useState(null);
    const [loader, setLoader] = useState(true);
    const {orderID} = useParams();
    const [urlParams] = useSearchParams();
    const [btnLoader, setBtnLoader] = useState(true);
    const [status, setStatus] = useState(false);
    const {user} = useOutletContext();

    useEffect(() => {
        if(!loader) setLoader(true);
        axios.post(`${process.env.REACT_APP_API_URL}/api/order-details/${orderID}`).then((res) => {
            if(res.status === 200) {
                setOrder(res.data);
                setLoader(false);
            }
        }).catch((err) => {
            console.log(`Error getting response ${err}`)
            window.alert("Couldn't fetch the order details");
        }).finally(() => setLoader(false))
    }, []);

    useEffect(() => {
        if(order) {
            if(user.user_id.toString() === order.approver_id.toString() && order.approver_status === "Pending approval") {
                const payload = {
                    order_id: orderID,
                    approver_status: urlParams.get('action') === "0" ? "Rejected" : "Approved"
                };
                if(!btnLoader) setBtnLoader(true);
                axios.post(`${process.env.REACT_APP_API_URL}/api/users/approveOrder`, payload).then((res) => {
                    if(res.status === 201) {
                        setStatus(true);
                        setBtnLoader(false);
                    }
                }).catch((err) => {
                    console.log(`Error submitting request: ${err}`)
                    window.alert("Something went wrong");
                    setBtnLoader(false)
                    setStatus(false);
                })
            }
        }
    }, [order]);

    return (
        <Stack flex={1} sx={{width: '100%'}} alignItems={'center'} justifyContent={'center'}>
            {
                loader ?
                    <CircularProgress />
                    :
                    <Stack sx={{width: '100%', height: {xs: '100%', md: 'fit-content'}, maxWidth: {sm: '540px'}, borderRadius: 4, border: '1px solid #e4e4e4', p: {xs: 1, md: 3}, }}>

                        <Stack gap={1} flexDirection={"row"} sx={{borderRadius: 1}} alignItems={'flex-start'}>
                            <Box flex={1} height={'min-content'}>
                                <img width={'100%'} height={'100%'} src={order.product_image || FallbackImage} alt={order.product_name} />
                            </Box>
                            <Stack flex={2}>
                                <Typography fontSize={'12px'} fontWeight={600}>{order.supplier_name}</Typography>
                                <Typography fontSize={'24px'} fontWeight={700}>{order.product_description}</Typography>
                                <Typography fontSize={'16px'} fontWeight={700}>{order.product_name}</Typography>
                                <Typography fontSize={'12px'} sx={{my: 1}}>{order.product_specifications}</Typography>
                            </Stack>
                        </Stack>
                        <Stack flexDirection={"row"} sx={{flexFlow: 'wrap', mt: 1}} >
                            <Stack alignItems={'flex-start'} flex={1}
                                   sx={{"& p": {fontSize: '14px', m: 1}}}>
                                <Typography><b>Ordered on:</b> {order.order_date}</Typography>
                                <Typography><b>Ordered by:</b> {order.full_name}</Typography>
                                <Typography><b>Location:</b> {order.loc}</Typography>
                                <Typography><b>Order Details:</b> {order.request_info}</Typography>
                            </Stack>
                            <Stack alignItems={'flex-start'} flex={1}
                                   sx={{"& p": {fontSize: '14px', m: 1}}}>
                                <Typography><b>Unit Price:</b> {order.unit_price}</Typography>
                                <Typography><b>Quantity:</b> {order.quantity}</Typography>
                                <Typography><b>Total Amount:</b> {order.total_amount}</Typography>
                            </Stack>

                        </Stack>


                        <Button
                            sx={{
                                width: '100%', borderRadius: 3, backgroundColor: '#c1ff72', textTransform: 'capitalize',
                                color: 'text.primary', my: 2, p: 1, height: '40px'
                            }}>
                            {
                                order.approver_status === "Pending approval" ?
                                    <>
                                        {
                                            urlParams.get('action') === "0" ?
                                                status ? "Declined" : "Declining"
                                                :
                                                status ? "Approved" : "Approving"

                                        }
                                        {
                                            btnLoader ? <CircularProgress sx={{color: 'white', p: 1}} /> : status ? <CheckCircleOutlined sx={{color: 'white', mx: 1}} /> : <></>
                                        }
                                    </>
                                : order.approver_status
                            }

                        </Button>

                    </Stack>
            }
        </Stack>
    )
}