import React, {useEffect, useState} from "react";
import {Card} from "../../components/Card";
import {
    CircularProgress,
    IconButton,
    Link,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {
    CheckCircleOutlined,
    CloudDownload,
    CloudUpload,
    Delete,
    Description,
    HighlightOff
} from "@mui/icons-material";
import sorter from "../../utils/sorter";
import axios from "axios";

export default function ContractUpload() {

    const [rows, setRows] = useState([]);
    const columns = [
        {
            id: "name",
            name: "Name",
            value: (props) => ({
                head: props.name,
                icon: <Description/>,
                url: `${process.env.REACT_APP_API_URL}/api/users/contracts/${props.fileURI}`
            })
        },
        {
            id: "size",
            name: "Size",
            value: (props) => ({name: formatFileSize(props.size)})
        },
        {
            id: "type",
            name: "Type",
            value: (props) => ({name: props.type})
        },
        {
            id: "uploadDate",
            name: "Upload Date",
            value: (props) => ({name: new Date(props.createdAt).toDateString(), subtitle: new Date(props.createdAt).toLocaleTimeString()})
        },
    ]

    const [status, setStatus] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [failed, setFailed] = useState(false);
    const filesAccepted = ["pdf", "doc", "docx"]
    const [loader, setLoader] = useState(false);

    const handleDelete = (fileURI) => {
        setLoader(true);
        axios.delete(`${process.env.REACT_APP_API_URL}/api/users/contracts/${fileURI}`).then((res) => {
            if(res) {
                getContracts();
            }
        }).catch((err) => {
            console.log("Error while deleting", err);
            window.alert("Contract deletion failed");
        });
    }

    const handleSort = (query) => {
        sorter(columns, rows, query, setRows)
    }

    const handleUploadProgress = (progressEvent) => {
        console.log(progressEvent);
        setStatus(progressEvent);
    }

    const uploadFile = async (data) => {
        setUploading(true);
        axios.post(`${process.env.REACT_APP_API_URL}/api/users/upload-contract`, data, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress: handleUploadProgress
        }).then((res) => {
            if(res.status === 200) {
                console.log("contract uploaded");
                getContracts();
            }
        }).catch((err) => {
            console.log(`Error uploading contract: ${err}`);
            setFailed(true);
        }).finally(() => {
            setUploading(false);
        });
    };

    const handleInput = (evt) => {
        if(uploading) return;
        const files = [...evt.target.files].filter((f) =>
                filesAccepted.includes(f.name.slice(f.name.lastIndexOf('.') + 1))
                &&
                f.size <= 5 * 1024 * 1024
            );
        console.log("Files accepted: ", files.map(f => f.name).join(", "));
        const formData = new FormData();
        files.forEach(f =>
            formData.append('file', f, f.name)
        )
        uploadFile(formData).then(() => evt.target.value = "");
    }

    const formatFileSize = (sizeInBytes) => {
        if (sizeInBytes >= 1048576) {
            return `${(sizeInBytes / 1048576).toFixed(2)} MB`;
        }
        if (sizeInBytes >= 1024) {
            return `${(sizeInBytes / 1024).toFixed(2)} KB`;
        }
        return `${sizeInBytes} bytes`;
    };

    const getContracts = () => {
        if(!loader) setLoader(true);
        axios.get(`${process.env.REACT_APP_API_URL}/api/users/contracts`).then((res) => {
            if(res.status === 200) {
                setRows(res.data);
            }
        }).catch((err) => {
            console.log(`error getting contracts ${err}`);
            window.alert("Error: couldn't fetch contracts");
        }).finally(() => setLoader(false));
    }

    useEffect(getContracts, []);


    return (
        <>
            <Card styleProps={{p: 2, height: 'fit-content'}}>
                <Stack sx={{height: 'fit-content', width: '100%'}} alignItems={"flex-start"} spacing={3}>
                    <Stack flexDirection={"row"}
                           sx={{height: 'fit-content', borderBottom: '1px solid #DFE3E8', width: '100%', pb: 2}}
                           justifyContent={"space-between"} alignItems={"center"}>

                        <Typography component={"h4"} fontSize={"18px"} fontWeight={700}>Contract Upload</Typography>
                    </Stack>

                    <Stack onClick={() => {
                        if (!uploading) document.querySelector('input[name=contract]')?.click();
                        if (!uploading && status && status.loaded === status.total) {
                            setStatus(null); setFailed(false);
                        }
                    }}
                           draggable={true}
                           alignItems={'center'} justifyContent={'center'}
                           sx={(theme) => ({
                               cursor: 'pointer',
                               p: 2.5, backgroundColor: theme.palette.background.neutral, width: '100%',
                               borderRadius: '12px', border: '1px dotted', borderColor: theme.palette.text.disabled
                           })}>
                        {
                            status ?
                                <>
                                    {
                                        uploading ? <CircularProgress sx={{width: '16px', p: 1, m: 2}}/> : failed ? <HighlightOff sx={{m: 2}} /> : <CheckCircleOutlined sx={{ m: 2}}/>
                                    }
                                    <Typography textAlign={'center'} fontSize={14}
                                                sx={(theme) => ({color: theme.palette.text.disabled, fontWeight: 'bold'})}>

                                        {status.loaded === status.total ?
                                            uploading ? "Processing..." : failed ? `Upload Failed` : `Upload Successful`
                                            :
                                            `Uploading... ${ status.loaded / status.total * 100 }%`}

                                    </Typography>
                                </>
                                :
                                <>
                                    <input onInput={handleInput} type={"file"} multiple accept={filesAccepted.map(f => `.${f}`).join(", ")} hidden={true}
                                           name={'contract'}/>
                                    <CloudUpload sx={{fontSize: '40px'}}/>
                                    <Typography textAlign={'center'} fontSize={14}
                                                sx={(theme) => ({color: theme.palette.text.disabled})}>
                                        Click to browse or drop here to upload.<br/>
                                        Supported Formats: {filesAccepted.map(f => `.${f}`).join(", ")}. Maximum Individual File size: 5 MB
                                    </Typography>
                                </>
                        }
                    </Stack>


                    <Table sx={{mt: 2, "& th, & td": {borderBottom: 0, whiteSpace: 'nowrap'}}}>

                        <TableHead sx={(theme) => ({backgroundColor: theme.palette.background.neutral})}>
                            <TableRow key={'selector'}>

                                <TableCell key={"select-all"} sx={{
                                    width: 'fit-content', display: 'block'
                                }}>
                                    <input name={'header'} type={'checkbox'}/>
                                </TableCell>
                                {
                                    columns.map((col, i) =>
                                        <TableCell key={`'col-${col.id}-${i}`}
                                                   sx={(theme) => ({"& span": {color: theme.palette.text.secondary}})}>
                                            <span style={{cursor: "pointer"}}
                                                  onClick={() => handleSort(col.id)}>{col.name}</span>
                                            {/*<ArrowDownwardOutlined />*/}
                                        </TableCell>
                                    )
                                }
                                <TableCell key={'options'}/>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {
                                loader ?
                                    <TableRow>
                                        <TableCell colSpan={6}><span style={{width: '100%', textAlign: 'center', display: 'block'}}><CircularProgress sx={{p: 1}} /></span></TableCell>
                                    </TableRow>
                                    :
                                rows.length ? rows.map((props, i) =>
                                    <TableRow key={`row-${props.id}-${i}`} sx={{"& td": {fontSize: '12px'}}}>
                                        <TableCell key={`select-${props.id}`} sx={{
                                            width: 'fit-content', display: 'block'
                                        }}>
                                            <input name={'header'} type={'checkbox'}/>
                                        </TableCell>
                                        {
                                            columns.map((c, j) => {
                                                const {img, icon, name, subtitle, head, url} = c.value(props);
                                                return <TableCell key={`col-cell-${c.id}-${j}`}>
                                                    <Stack gap={2} flexDirection={"row"}
                                                           sx={{height: 'fit-content', width: '100%'}}
                                                           alignItems={"center"}>
                                                        {
                                                            img ? <img src={img} alt={name}
                                                                       style={{
                                                                           aspectRatio: 1,
                                                                           width: "48px",
                                                                           objectFit: 'cover'
                                                                       }}/>
                                                                : icon ? icon : <></>
                                                        }
                                                        <Stack>
                                                            {
                                                                head ? <Link sx={{textDecoration: 'none'}} target={"_blank"} href={url}>
                                                                        <Typography fontSize={14} fontWeight={600}>{head}</Typography></Link>
                                                                    : <></>
                                                            }
                                                            {
                                                                name ? <Typography fontSize={12}>{name}</Typography>
                                                                    : <></>
                                                            }
                                                            {
                                                                subtitle ? <Typography fontSize={12}
                                                                                       sx={(theme) => ({color: theme.palette.text.secondary})}>{subtitle}</Typography>
                                                                    : <></>
                                                            }

                                                        </Stack>
                                                    </Stack>
                                                </TableCell>
                                            })
                                        }
                                        <TableCell sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between'
                                        }}>
                                            <Link href={`${process.env.REACT_APP_API_URL}/api/users/contracts/${props.fileURI}`} download={true} target={"_blank"}><CloudDownload sx={{fontSize: '20px'}}/></Link>
                                            <IconButton onClick={() => window.confirm("Are you sure?") && handleDelete(props.fileURI) }><Delete/></IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                                    :
                                    <TableRow>
                                        <TableCell colSpan={6}><span style={{width: '100%', textAlign: 'center', display: 'block'}}>No files available</span></TableCell>
                                    </TableRow>
                            }
                        </TableBody>

                    </Table>


                </Stack>


            </Card>
        </>
    )
}