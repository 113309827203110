import axios from "axios";

export default async function sendOTP(email){
    const otpData = {
        email,
        subject: 'OTP for verification',
        message: 'see the verification OTP',
        duration: 1,
    };

    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/users/otp`, otpData);
        return (response.status === 200)
    } catch (error) {
        console.error('Error sending OTP:', error.response.data);
        throw error; // Rethrow the error to handle it further up the call stack
    }
};