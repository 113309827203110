import {Autocomplete, InputAdornment, TextField} from "@mui/material";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";

export default function SearchBar() {

    const [options, setOptions] = useState([]);
    const navigate = useNavigate();

    const handleChange = (evt) => {
        const query = evt.target.value.trim();
        axios.post(`${process.env.REACT_APP_API_URL}/api/search`, {term: query}).then((res) => {
            if(res.status === 200) {
                setOptions(res.data);
            }
        }).catch((err) => console.log(`Error while searching: ${err}`));
    }

    return (
        <Autocomplete
            disablePortal freeSolo
            id="combo-box-demo"
            onInputChange={(e, value, reason) => {if(reason === "reset") navigate(`/dashboard/search/${encodeURI(value)}`);}}
            options={options.map((op) => op.product_name)}
            sx={(theme) => ({
                maxWidth: "60%",
                width: "100%",
                background: "#FFF",
                color: theme.palette.text.primary,
                borderRadius: "6px",
                display: {xs: 'none', md: 'flex'}
            })}
            renderInput={
                (params) => {
                    params.InputProps.startAdornment = (
                        <InputAdornment position={"start"}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                 viewBox="0 0 24 24">
                                <g id="evaSearchFill0">
                                    <g id="evaSearchFill1">
                                        <path id="evaSearchFill2" fill="currentColor"
                                              d="m20.71 19.29l-3.4-3.39A7.92 7.92 0 0 0 19 11a8 8 0 1 0-8 8a7.92 7.92 0 0 0 4.9-1.69l3.39 3.4a1 1 0 0 0 1.42 0a1 1 0 0 0 0-1.42ZM5 11a6 6 0 1 1 6 6a6 6 0 0 1-6-6Z"/>
                                    </g>
                                </g>
                            </svg>
                        </InputAdornment>
                    )
                    params.InputProps.endAdornment = null;

                    return <TextField
                        // inputProps={params.inputProps}

                        placeholder={"Would you like to place a new request?"}
                        sx={(theme) => ({
                            "& input::placeholder": {fontSize: '14px'},
                            "& .MuiAutocomplete-input": {padding: '0!important'},
                            "& .MuiAutocomplete-inputRoot": {
                                px: 2,
                                py: 1.5,
                                borderColor: "transparent"
                            },
                            "& .MuiOutlinedInput-root ": {outline: "none"}
                        })}
                        onChange={handleChange}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") navigate(`/dashboard/search/${encodeURI(e.target.value)}`)
                        }}
                        InputProps={{
                            type: "search",
                            style: {paddingRight: "14px", paddingLeft: "14px"},
                        }}
                        {...params}
                    />
                }
            }
        />
    )
}