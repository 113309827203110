import React, {useEffect, useState} from "react";
import {
    Box,
    CircularProgress, Stack,
    Typography
} from "@mui/material";
import {Circle, StarRounded} from "@mui/icons-material";
import axios from "axios";
import {useParams} from "react-router-dom";
import FallbackImage from "../../static/img/fallback.png";


export default function OrderDetails() {

    const {orderID} = useParams();
    const [order, setOrderDetails] = useState(null);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        if (!loader) setLoader(true);
        axios.post(`${process.env.REACT_APP_API_URL}/api/order-details/${orderID}`, {}).then((res) => {
            if (res.status === 200) {
                setLoader(false);
                setOrderDetails(res.data);
            }
        }).catch((err) => {
            console.log(`Error getting order details: ${err}`);
        });
    }, []);

    if (loader)
        return <Stack sx={{width: '100%', flex: 1}} alignItems={'center'} justifyContent={'center'}
                      spacing={1}><CircularProgress/></Stack>

    return (
        <>
            <Stack sx={{width: '100%'}} spacing={1}>
                <Typography component={"h4"} fontSize={'24px'} fontWeight={700}>Order Details</Typography>
                <Stack sx={{width: '100%'}} alignItems={'center'} flexDirection={'row'} gap={1.5}>
                    <Typography fontSize={'14px'}>Dashboard</Typography>
                    <Circle sx={(theme) => ({fill: theme.palette.text.disabled, width: '5px'})}/>
                    <Typography fontSize={'14px'} sx={(theme) => ({color: theme.palette.text.disabled})}>Order
                        Details</Typography>
                </Stack>
            </Stack>
            <Stack flexDirection={"row"} gap={2} flex={2}>

                <Stack flex={1} spacing={3} sx={{
                    height: 'fit-content',
                    borderRadius: 2,
                    px: 4,
                    py: 3,
                    width: '100%',
                    background: 'linear-gradient(135deg, rgba(91, 228, 155, 0.20) 0%, rgba(0, 167, 111, 0.20) 100%)'
                }}>

                    <Stack spacing={.5} sx={{"& p": {color: '#004B50'}}}>
                        <Typography fontWeight={700} fontSize={24}>Delivery Pending...</Typography>
                        <Typography fontWeight={700} fontSize={18}>Your item is expected to be delivered by {order.order_date}</Typography>
                        <Typography sx={{color: '#118D57'}} fontSize={12}>Ordered
                            on <b>{new Date(order.createdAt).toDateString()}</b></Typography>
                    </Stack>

                    <Stack gap={5} flexDirection={"row"}>
                        <Stack sx={{flex: 2}} spacing={2}>
                            <Stack>
                                <Typography fontSize={'20px'}
                                            fontWeight={700}>{order.product_description} | {order.product_name}</Typography>
                                <Stack flexDirection={"row"} alignItems={'center'}>
                                    {Array(5).fill(0).map((x, i) => <StarRounded key={i.toString()} sx={(theme) => ({
                                        width: 20,
                                        fill: i <= 3 ? '#FFAB00' : theme.palette.text.disabled
                                    })}/>)}
                                    <Typography sx={(theme) => ({mx: 1, color: theme.palette.text.disabled})}
                                                fontSize={'14px'}>(12k
                                        reviews)</Typography>
                                </Stack>
                            </Stack>

                            <Stack flexDirection={"row"} alignItems={'center'} gap={1}>
                                {/*<Typography fontWeight={700} fontSize={'20px'} sx={(theme) => ({*/}
                                {/*    color: theme.palette.text.disabled,*/}
                                {/*    textDecoration: 'line-through'*/}
                                {/*})}>${order.unit_price * Number.parseInt(order.quantity) * 1.25}</Typography>*/}
                                <Typography fontWeight={700} fontSize={'20px'}
                                            sx={{}}>{order.total_amount}</Typography>
                            </Stack>

                            <Stack>
                                <Typography fontSize={'12px'} fontWeight={700} sx={{color: '#00B8D9'}}>ABOUT THIS
                                    ITEM</Typography>
                                <Typography fontSize={'14px'}
                                            sx={(theme) => ({color: theme.palette.text.secondary})}>{order.product_specifications}</Typography>
                            </Stack>

                        </Stack>
                        <Box sx={{display: 'flex', flex: 1}}>
                            <img src={order.product_image || FallbackImage} alt={order.product_name}
                                 style={{width: '100%', height: 'auto', borderRadius: "16px", objectFit: 'contain'}}/>
                        </Box>
                    </Stack>

                </Stack>

                <Stack flex={1}>
                    <Stack sx={{p: 2, borderRadius: 2, width: '100%'}} spacing={1.5}>

                        <Typography sx={{color: '#00B8D9'}} fontWeight={600} fontSize={14}>Order
                            Information</Typography>

                        <Stack spacing={2} flexWrap={'wrap'} alignItems={'flex-start'} justifyContent={'space-evenly'}>

                            <Stack flexDirection={"row"} alignItems={'flex-start'} justifyContent={"space-between"}
                                   flex={1} width={"100%"}>

                                <Stack justifyContent={'flex-start'} flex={1} minWidth={'150px'}>
                                    <Typography fontSize={14}
                                                sx={(theme) => ({color: theme.palette.text.secondary})}>Price</Typography>
                                    <Typography fontSize={32} fontWeight={700}>{order.total_amount}</Typography>
                                </Stack>
                                <Stack justifyContent={'flex-start'} flex={1} minWidth={'150px'}>
                                    <Typography fontSize={14}
                                                sx={(theme) => ({color: theme.palette.text.secondary})}>Quantity</Typography>
                                    <Typography fontSize={32} fontWeight={700}>{order.quantity}</Typography>
                                </Stack>

                            </Stack>

                            <Stack flexDirection={"row"} width={'100%'} alignItems={'center'}
                                   justifyContent={'space-between'} flex={1} minWidth={'150px'}>
                                <Typography flex={1} fontSize={14}
                                            sx={(theme) => ({color: theme.palette.text.secondary})}>Name of
                                    Supplier</Typography>
                                <Typography flex={1} fontSize={14} fontWeight={600}>{order.supplier_name}</Typography>
                            </Stack>

                            <Stack flexDirection={"row"} width={'100%'} alignItems={'center'}
                                   justifyContent={'space-between'} flex={1} minWidth={'150px'}>
                                <Typography flex={1} fontSize={14}
                                            sx={(theme) => ({color: theme.palette.text.secondary})}>Specification</Typography>
                                <Typography flex={1} fontSize={14} fontWeight={600}>{order.product_specifications}</Typography>
                            </Stack>

                            <Stack flexDirection={"row"} width={'100%'} alignItems={'center'}
                                   justifyContent={'space-between'} flex={1} minWidth={'150px'}>
                                <Typography flex={1} fontSize={14}
                                            sx={(theme) => ({color: theme.palette.text.secondary})}>Approved
                                    by</Typography>
                                <Typography flex={1} fontSize={14} fontWeight={600}>{order.full_name}</Typography>
                            </Stack>

                            <Stack flexDirection={"row"} width={'100%'} alignItems={'center'}
                                   justifyContent={'space-between'} flex={1} minWidth={'150px'}>
                                <Typography flex={1} fontSize={14}
                                            sx={(theme) => ({color: theme.palette.text.secondary})}>Product
                                    Information</Typography>
                                <Typography flex={1} fontSize={14} fontWeight={600}>{order.product_description}</Typography>
                            </Stack>

                            <Stack flexDirection={"row"} width={'100%'} alignItems={'center'}
                                   justifyContent={'space-between'} flex={1} minWidth={'150px'}>
                                <Typography flex={1} fontSize={14}
                                            sx={(theme) => ({color: theme.palette.text.secondary})}>Category</Typography>
                                <Typography flex={1} fontSize={14} fontWeight={600}>{order.cate}</Typography>
                            </Stack>

                            <Stack flexDirection={"row"} width={'100%'} alignItems={'center'}
                                   justifyContent={'space-between'} flex={1} minWidth={'150px'}>
                                <Typography flex={1} fontSize={14}
                                            sx={(theme) => ({color: theme.palette.text.secondary})}>Required
                                    By</Typography>
                                <Typography flex={1} fontSize={14} fontWeight={600}>{order.order_date}</Typography>
                            </Stack>

                            <Stack flexDirection={"row"} width={'100%'} alignItems={'center'}
                                   justifyContent={'space-between'} flex={1} minWidth={'150px'}>
                                <Typography flex={1} fontSize={14}
                                            sx={(theme) => ({color: theme.palette.text.secondary})}>Location</Typography>
                                <Typography flex={1} fontSize={14} fontWeight={600}>{order.loc}</Typography>
                            </Stack>

                            <Stack flexDirection={"row"} width={'100%'} alignItems={'center'}
                                   justifyContent={'space-between'} flex={1} minWidth={'150px'}>
                                <Typography flex={1} fontSize={14}
                                            sx={(theme) => ({color: theme.palette.text.secondary})}>Details about
                                    request</Typography>
                                <Typography flex={1} fontSize={14}
                                            fontWeight={600}>{order.request_info || "-"}</Typography>
                            </Stack>

                        </Stack>

                    </Stack>
                </Stack>

            </Stack>
        </>
    )
}