import React from "react";
import {Box, Button, Menu, MenuItem} from "@mui/material";
import {KeyboardArrowDown, KeyboardArrowUp, Sort} from "@mui/icons-material";

export default function Sorter({columns, handleCloseSuper}) {

    const [anchor, setAnchor] = React.useState(null);
    const open = Boolean(anchor);

    const handleClick = (evt) => {
        setAnchor(evt.currentTarget);
    };

    const handleClose = (evt) => {
        handleCloseSuper(evt);
        setAnchor(null);
    }

    return (
        <>
            <Box sx={{position: 'relative'}}>
                <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    startIcon={<Sort/>}
                    endIcon={open ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
                    sx={(theme) => ({
                        textTransform: "capitalize", color: theme.palette.text.primary, px: 2,
                        "&:hover": {
                            backgroundColor: "#919EAB29"
                        }
                    })}
                >
                    Sort by
                </Button>
                <Menu
                    id="basic-menu"
                    sx={{borderRadius: '4px', "& li": {fontSize: '14px'}}}
                    anchorEl={anchor}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {
                        columns.map((col, i) =>
                            <MenuItem key={`sorter-${col.id}-${i.toString()}`} aria-description={col.id} onClick={handleClose}>{col.name}</MenuItem>
                        )
                    }
                </Menu>
            </Box>
        </>
    )
}