import React, {useEffect, useState} from "react";
import FallbackImage from "../../static/img/fallback.png";
import {useNavigate, useParams} from "react-router-dom";
import {
    Button,
    CircularProgress,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Typography
} from "@mui/material";
import {Add, Circle, Remove, StarRounded} from "@mui/icons-material";
import axios from "axios";
import {InputTextField} from "../../components/InputTextField";

export default function ProductDetails() {

    const {productID} = useParams();
    const [product, setProduct] = useState(null);
    const [loader, setLoader] = useState(true);
    const [location, setLocation] = useState(null);
    const [locationList, setLocationList] = useState([]);
    const [details, setDetails] = useState("");
    const [deliverBy, setDeliverBy] = useState("");
    const navigate = useNavigate();
    const [quant, setQuant] = useState(1);

    useEffect(() => {
        if (!loader) setLoader(true);
        axios.post(`${process.env.REACT_APP_API_URL}/api/users/productInfo`, {product_id: productID}).then((res) => {
            if (res.status === 200) {
                setLoader(false);
                setProduct(res.data);
            }
        }).catch((err) => console.log(`Error getting product info ${err}`));
    }, []);

    useEffect(() => {
        axios.post(`${process.env.REACT_APP_API_URL}/api/users/locationList`, null).then((res) => {
            if (res.status === 200) {
                setLocationList(res.data);
            }
        }).catch((err) => console.log(`Error getting location list ${err}`));
    }, []);

    const handleSubmit = () => {
        const payload = {
            order_date: deliverBy,
            locationId: location,
            requestInfo: details,
            quantity: quant,
            product_id: productID
        };
        axios.post(`${process.env.REACT_APP_API_URL}/api/users/placeOrder`, payload).then((res) => {
            if(res.status === 201) {
                window.alert("Order placed successfully");
                navigate("/dashboard");
            }
        }).catch((err) => console.log(`Error placing order ${err}`));
    }

    if (loader)
        return <Stack sx={{width: '100%', flex: 1}} alignItems={'center'} justifyContent={'center'}
                      spacing={1}><CircularProgress/></Stack>

    return (
        <>
            <Stack sx={{width: '100%'}} spacing={1}>
                <Typography component={"h4"} fontSize={'24px'} fontWeight={700}>Product Details</Typography>
                <Stack sx={{width: '100%'}} alignItems={'center'} flexDirection={'row'} gap={1.5}>
                    <Typography fontSize={'14px'}>Dashboard</Typography>
                    <Circle sx={(theme) => ({fill: theme.palette.text.disabled, width: '5px'})}/>
                    <Typography fontSize={'14px'} sx={(theme) => ({color: theme.palette.text.disabled})}>Product
                        Details</Typography>
                </Stack>
            </Stack>
            <Stack gap={5} flexDirection={"row"} sx={{
                borderRadius: 2,
                px: 4,
                py: 3,
                width: '100%',
                background: 'linear-gradient(135deg, rgba(91, 228, 155, 0.20) 0%, rgba(0, 167, 111, 0.20) 100%)'
            }}>
                <img src={product.image || FallbackImage} onError={(evt) => evt.target.src = FallbackImage} alt={product.product_name}
                     style={{height: '200px', aspectRatio: 1.5, borderRadius: "16px", objectFit: 'contain'}}/>
                <Stack sx={{flex: 1}} spacing={2}>

                    <Stack>
                        <Typography fontSize={'20px'}
                                    fontWeight={700}>{product.product_description} | {product.product_name}</Typography>
                        <Stack flexDirection={"row"} alignItems={'center'}>
                            {Array(5).fill(0).map((x, i) => <StarRounded key={i.toString()} sx={(theme) => ({
                                width: 20,
                                fill: i <= 3 ? '#FFAB00' : theme.palette.text.disabled
                            })}/>)}
                            <Typography sx={(theme) => ({mx: 1, color: theme.palette.text.disabled})} fontSize={'14px'}>(11.8k
                                reviews)</Typography>
                        </Stack>
                    </Stack>

                    <Stack flexDirection={"row"} alignItems={'center'} gap={1}>
                        {
                            product.discounted_unit_price ? <Typography fontWeight={700} fontSize={'20px'} sx={(theme) => ({
                                color: theme.palette.text.disabled,
                                textDecoration: 'line-through'
                            })}>${product.discounted_unit_price}</Typography> : <></>
                        }
                        <Typography fontWeight={700} fontSize={'20px'} sx={{}}>${product.unit_price * quant}</Typography>
                    </Stack>

                    <Stack>
                        <Typography fontSize={'12px'} fontWeight={700} sx={{color: '#00B8D9'}}>ABOUT THIS
                            ITEM</Typography>
                        <Typography fontSize={'14px'}
                                    sx={(theme) => ({color: theme.palette.text.secondary})}>{product.product_specifications || product.product_description || "-"}</Typography>
                    </Stack>

                    <Stack flexDirection={"row"} alignItems={'center'} gap={2}>
                        <Typography fontSize={'14px'}>Quantity</Typography>
                        <Stack flexDirection={"row"} alignItems={'center'}
                               sx={{border: '1px solid', borderColor: '#919EAB33', borderRadius: 2, p: 0.5}} gap={1}>
                            <IconButton onClick={() => setQuant(Math.max(quant - 1, 1))} sx={{p: 0, mx: .5}}><Remove
                                sx={{width: '20px'}}/></IconButton>
                            <Typography fontSize={'14px'} sx={{mx: .5}}>{quant}</Typography>
                            <IconButton onClick={() => setQuant(quant + 1)} sx={{p: 0, mx: .5}}><Add
                                sx={{width: '20px'}}/></IconButton>
                        </Stack>
                    </Stack>

                </Stack>
            </Stack>

            <Stack sx={{p: 2, borderRadius: 2, width: '100%'}} spacing={1.5}>
                <Typography sx={{color: '#00B8D9'}} fontWeight={600} fontSize={14}>Order Information</Typography>

                <Stack flexDirection={'row'} flexWrap={'wrap'} alignItems={'center'} justifyContent={'space-evenly'}>

                    <Stack justifyContent={'flex-start'} flex={1}>
                        <Typography fontSize={14}
                                    sx={(theme) => ({color: theme.palette.text.secondary})}>Specification</Typography>
                        <Typography fontSize={14} fontWeight={600}>{product.product_specifications || product.product_description || "-"}</Typography>
                    </Stack>

                    <Stack justifyContent={'flex-start'} flex={1}>
                        <Typography fontSize={14}
                                    sx={(theme) => ({color: theme.palette.text.secondary})}>Category</Typography>
                        <Typography fontSize={14} fontWeight={600}>{product.category}</Typography>
                    </Stack>

                    <Stack justifyContent={'flex-start'} flex={1}>
                        <Typography fontSize={14} sx={(theme) => ({color: theme.palette.text.secondary})}>Approval
                            Required?</Typography>
                        <Typography fontSize={14} fontWeight={600}>{product.contract ? "Yes" : "No"}</Typography>
                    </Stack>

                    <Stack justifyContent={'flex-start'} flex={1}>
                        <Typography fontSize={14}
                                    sx={(theme) => ({color: theme.palette.text.secondary})}>Supplier</Typography>
                        <Typography fontSize={14} fontWeight={600}>{product.supplier_name || "-"}</Typography>
                    </Stack>

                </Stack>

                <Stack flexDirection={'row'} sx={{pt: 2, "& *, & *": {fontSize: '14px!important'}}}
                       flexWrap={'wrap'} alignItems={'center'}
                       justifyContent={'space-between'} gap={2}
                >
                    <Stack sx={{flex: 1}}>
                        <InputLabel sx={{m: .5}}>When do you require this by?</InputLabel>
                        <InputTextField InputProps={{
                            inputProps: {
                                type: "date", fontSize: '12px', min: (() => {
                                    const date = new Date();
                                    return `${date.getUTCFullYear()}-${date.getUTCMonth() + 1}-${date.getUTCDate()}`
                                })()
                            }
                        }}
                                        sx={{fontSize: '12px'}} name={"deliverBy"}
                                        onChange={(e) => setDeliverBy(e.target.value)}/>
                    </Stack>
                    <Stack sx={{flex: 1}}>
                        <InputLabel sx={{m: .5}} id="location-label">Choose Location</InputLabel>
                        <Select required name={"location"} value={location ? location : "location"}
                                onChange={(e) => setLocation(e.target.value)}>
                            <MenuItem key={"default"} disabled value={"location"}>Choose Location</MenuItem>
                            {
                                locationList.map((lo) =>
                                    <MenuItem key={lo.id} value={`${lo.id}`}
                                              sx={{textTransform: 'capitalize'}}>{lo.city}, {lo.state}</MenuItem>
                                )
                            }
                        </Select>
                    </Stack>
                    <Stack sx={{flex: 1}}>
                        <InputLabel sx={{m: .5}}>Details about request</InputLabel>
                        <InputTextField name={"details"} placeholder={"Enter Details"}
                                        onChange={(e) => setDetails(e.target.value)}
                        />
                    </Stack>

                </Stack>

                <Stack width={"100%"} alignItems={"flex-end"} justifyContent={"flex-end"}>
                    <Button onClick={handleSubmit} sx={(theme) => ({
                        p: 1,
                        height: 'fit-content',
                        width: '180px',
                        backgroundColor: "#C1FF72",
                        my: 2,
                        color: theme.palette.text.primary,
                        fontWeight: 600,
                        fontSize: '14px',
                        textTransform: "capitalize"
                    })}>Place Order</Button>
                </Stack>
            </Stack>
        </>
    )
}