import React from "react";
import {Outlet} from "react-router-dom";
import authOverlay from "./../static/img/authOverlay.png"
import authHeadLogo from "./../static/img/authHeadLogo.svg"
import {styled} from "@mui/material/styles";
import {Box, Link, Stack} from "@mui/material";


const BackGroundOverlay = styled('div')(( { theme } ) => ({
    backgroundImage: `url('${authOverlay}')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    minHeight: '100%',
    overflowY: "auto"
}));


export default function AuthenticationLayout() {

    return (
        <>
            <BackGroundOverlay>
                <Stack flexDirection={'column'} sx={{width: '100%', position: 'relative', minHeight: '100%', overflowY: 'auto'}}>

                    {/* used stack to add more elements in the header in the future, just in case */}
                    <Box sx={{width: '100%', px: 3, py: 2.5, position: {xs: 'sticky', md: 'relative'}, top: 0,}}>
                        <Link href={"/"}>
                            <img style={{height: '2.25rem'}} alt={"Nimbey"} src={authHeadLogo} />
                        </Link>
                    </Box>

                    <Outlet />

                </Stack>
            </BackGroundOverlay>
        </>
    )
}
