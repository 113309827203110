import React, {useEffect, useState} from "react";
import {
    Button, Grid,
    Stack,
    Typography
} from "@mui/material";
import axios from "axios";
import {Card, CardLink} from "../../components/Card";
import UserOrders from "../../components/UserOrders";
import FallbackImage from "../../static/img/fallback.png";

export default function DashboardHome() {

    const [featuredProducts, setFeaturedProducts] = useState([]);

    // featured products
    useEffect(() => {
        const products = {}
        const handleRes = (cat, res) => {
            if (res.status === 200) {
                if (res.data.length && !products.hasOwnProperty(cat)) products[cat] = {};
                res.data.slice(0, 3).forEach(
                    (r) => products[cat][r.product_id] = {
                        img: r.product_image,
                        name: r.product_description,
                        id: r.product_id
                    }
                )
            }
        }

        (async () => {
            const cats = await axios.post(`${process.env.REACT_APP_API_URL}/api/users/categoryList`);

            for (const cat of cats.data.slice(0, 3)) {
                const prods = await axios.post(`${process.env.REACT_APP_API_URL}/api/search`,
                    {term: cat.category});
                handleRes(cat.category, prods);
            }

            setFeaturedProducts(Object.keys(products).map((cat) => ({
                category: cat,
                products: Object.values(products[cat])
            })))
        })();

    }, []);

    return (
        <>
            {/* Featured Products */}
            <Grid container columns={12} flexWrap={"wrap"} flexDirection={"row"} alignItems={'center'} spacing={2}
                  width={'100%'}
                  sx={{
                      "& .MuiGrid-item": {
                          "&:nth-child(1)": {paddingLeft: 0}
                      }
                  }}
            >

                {/* Featured Products */}
                {
                    featuredProducts.map((cat) =>
                        <Grid key={`cat-${cat.category}`} item xs={12} md={6} lg={4}>
                            <Card styleProps={{height: "fit-content"}} key={cat.category}>
                                <Stack sx={{mb: 2}} gap={2} flexDirection={"row"} alignItems={"center"}
                                       justifyContent={"space-between"}>
                                    <Typography sx={{textTransform: 'capitalize'}} fontSize={"18px"}
                                                fontWeight={700}>{cat.category}</Typography>
                                    <CardLink sx={{fontWeight: 600}} href={`/dashboard/search/${encodeURI(cat.category)}`}>View
                                        More</CardLink>
                                </Stack>
                                <Stack gap={2} flexDirection={"row"} alignItems={"center"}
                                       justifyContent={"flex-start"}>
                                    {
                                        cat.products.map((prod, i) =>
                                            <Button href={`/dashboard/products/${prod.id}`} key={`prod-${prod.name}-${i}`} sx={{p: 0, position: 'relative'}}>
                                                <img src={prod.img || FallbackImage}
                                                     style={{
                                                         borderRadius: '4px',
                                                         objectFit: 'cover',
                                                         minWidth: '50px',
                                                         width: '100px',
                                                         aspectRatio: 1
                                                     }}
                                                     alt={prod.name}/>
                                            </Button>
                                        )
                                    }
                                </Stack>
                            </Card>
                        </Grid>
                    )
                }

                {/* New Item Card */}
                {/*<Grid item xs={12} md={12} lg={4} height={{xs: 'fit-content', lg: "100%"}}>*/}
                {/*    <Card key={"newItem"} width={"100%"} height={"100%"} flexDirection={"row"} alignItems={"center"}*/}
                {/*          justifyContent={"space-between"}>*/}
                {/*        <CardLink sx={{height: "100%", display: 'flex', alignItems: 'center', justifyContent: "center"}}*/}
                {/*                  href={"/dashboard/products/software"}>*/}
                {/*            <ArrowOutwardOutlined fill={"#00A76F"} sx={{m: 1}}/>*/}
                {/*            <Typography component={"span"} color={"#00A76F"} fontSize={"14px"} fontWeight={600}>*/}
                {/*                New Item*/}
                {/*            </Typography>*/}
                {/*        </CardLink>*/}
                {/*    </Card>*/}
                {/*</Grid>*/}

            </Grid>

            {/* Order History */}
            <UserOrders/>

        </>
    )
}