export const palette = {
    text: {
        primary: '#212B36',
        secondary: '#637381',
        disabled: '#919EAB',
        info: "#00B8D9"
    },
    primary: {
        main: '#00A76F',
    },
    background: {
        paper: "#FFF",
        default: "#FFF",
        neutral: "#F4F6F8"
    },
}