import {Box, IconButton, Stack, Typography} from "@mui/material";
import {KeyboardArrowDownOutlined} from "@mui/icons-material";
import React from "react";

export default function UserMenu({ user }) {

    return (
        <Stack flexDirection={"row"} alignItems={"center"} flex={1} justifyContent={"flex-end"}>
            <Box sx={{
                backgroundColor: "#F5E9EA", borderRadius: "100%", height: '80%', aspectRatio: 1,
                display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Typography component={"span"} fontSize={14}
                            color={"#97262A"}>{user.userName.split(" ").map((w) => w[0]).join("")}</Typography>
            </Box>
            <Stack sx={{px: 1, "& span": {color: "#FFF"}, display: {xs: 'none', md: 'flex'}}}>
                <Typography component={"span"} sx={{fontSize: 14}}>{user.userName}</Typography>
                <Typography component={"span"} sx={{fontSize: 12}}>{user.user}</Typography>
            </Stack>
            <IconButton sx={{p: 0, display: {xs: 'none', md: 'flex'}}}>
                <KeyboardArrowDownOutlined sx={{fill: "#FFF"}}/>
            </IconButton>
        </Stack>
    )
}