import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {
    Box,
    CircularProgress,
    Stack
} from "@mui/material";
import Sidebar from "../components/Sidebar";
import {Outlet, useNavigate} from "react-router-dom";
import axios from "axios";
import Nimbey from "../static/img/sideHeadLogo.svg";
import SearchBar from "../components/SearchBar";
import UserMenu from "../components/UserMenu";


const getUser = async () => {
    return await axios.post(`${process.env.REACT_APP_API_URL}/api/users/home`, {});
}

export default function DashboardLayout() {

    const [user, setUser] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        getUser().then((res) => {
            if (res.status === 200) {
                if (res.data.status) {
                    setUser(res.data);
                } else {
                    navigate("/login");
                }
                console.log(res.data);
            }
        }).catch((e) => {
            console.log("No session found ", e);
            navigate("/login");
        })
    }, [navigate]);


    return (
        <>
            <Helmet>
                <title>Dashboard | Nimbey</title>
            </Helmet>

            <Stack flexDirection={"row"} sx={{width: '100%', height: '100%'}}>

                <Sidebar user={user}/>

                <Stack sx={{flex: 1, height: '100%', position: "relative", width: {xs: "100%"}, overflow: 'hidden'}}>
                    {/* Dashboard Header */}
                    <Stack flexDirection={"row"} justifyContent={"space-between"}  sx={{width: '100%', height: "fit-content", px: 3, py: 2, backgroundColor: '#212B36'}}>

                        <Box sx={{display: {xs: "block", md: "none"}}}>
                            <img src={Nimbey} alt={"nimbey-logo"} style={{maxHeight: "36px"}}/>
                        </Box>

                        {/* search bar */}
                        <SearchBar/>

                        {/* user menu */}
                        {   user ? <UserMenu user={user} /> : <></>  }

                    </Stack>

                    {/* Renders Different Pages */}
                    <Stack sx={{flex: 1, p: 2, overflowY: "auto", width: "100%", overflowX: "hidden"}} spacing={3}
                           alignItems={'center'} justifyContent={user ? 'flex-start' : 'center'}>

                        {   user ? <Outlet context={{user: user}}/> : <CircularProgress/>  }

                    </Stack>
                </Stack>

            </Stack>
        </>
    )
}