import {Card} from "./Card";
import {Button, Stack, Typography} from "@mui/material";
import DataTable from "./DataTable";
import React, {useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import axios from "axios";
import sorter from "../utils/sorter";
import Sorter from "./Sorter";


const Tabs = styled(Button)(({theme}) => ({
    color: theme.palette.text.primary,
    textTransform: 'none',
    fontSize: "14px",
    borderRadius: 4,
    fontWeight: 600,
    paddingBottom: ".75rem",
    paddingTop: ".75rem",
    whiteSpace: "nowrap",
    "&:hover": {
        backgroundColor: '#e4e4e4',
    },
    "&.tab-selected": {
        borderBottom: "2px solid",
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderColor: theme.palette.text.primary
    }
}));


export const columnSet = (tab) => [
    {
        id: "description",
        name: "Description",
        render: (props) => ({
            img: props.product_image,
            head: props.product_description,
            subtitle: props.product_name
        }),
        value: (props) => props.product_description
    },
    tab === 3 ? {
        id: "deliveredDate",
        name: "Delivered Date",
        render: (props) => ({name: new Date(props.order_date).toDateString()}),
        value: (props) => props.order_date
    } : {
        id: "orderDate",
        name: "Order Date",
        render: (props) => ({name: new Date(props.createdAt).toDateString()}),
        value: (props) => props.createdAt
    },
    {
        id: "amount",
        name: "Total Amount",
        render: (props) => ({name: `${props.total_amount}`}),
        value: (props) => props.total_amount
    },
    tab === 0 ? {
        id: "status",
        name: "Status",
        render: (props) => ({chip: props.status}),
        value: (props) => props.status
    } : null
]


export default function UserOrders() {

    const tabs = ["All Orders", "Pending Approval", "Approved", "Delivered", "Declined"];
    const [tab, setTab] = useState(0);
    const [rows, setRows] = useState([]);
    const columns = columnSet(tab);

    // table data
    useEffect(() => {
        axios.post(`${process.env.REACT_APP_API_URL}/api/previous-orders`, {}).then((res) => {
            if (res.status === 200) {
                setRows(res.data);
            }
        }).catch((err) => console.log(`Error getting orders: ${err}`));

    }, [tab]);

    const handleSort = (query) => {
        sorter(columns, rows, query, setRows)
    }

    const handleClose = (e) => {
        if(e.target.ariaDescription)
            handleSort(e.target.ariaDescription);
    }

    return (
        <Stack spacing={1.5} flexDirection={"row"} sx={{height: 'fit-content', width: '100%'}}
               alignItems={"center"}>
            <Card sx={{p: 2}}>
                <Stack sx={{height: 'fit-content', width: '100%', overflowX: 'auto'}} alignItems={"flex-start"}>
                    <Stack flexDirection={"row"}
                           sx={{height: 'fit-content', borderBottom: '1px solid #DFE3E8', width: '100%', pb: 2}}
                           justifyContent={"space-between"} alignItems={"center"}>

                        <Typography component={"h4"} fontSize={"18px"} fontWeight={700}>Your Past
                            Orders</Typography>

                        <Sorter handleCloseSuper={handleClose} columns={columns.filter(c => c !== null)} />

                    </Stack>

                    <Stack gap={2} flexDirection={"row"} sx={{height: 'fit-content'}} alignItems={"center"}>
                        {
                            tabs
                                .map((t, i) =>
                                    <Tabs onClick={() => setTab(i)} key={t}
                                          className={tab === i ? "tab-selected" : null} variant={'text'}>
                                        {t}
                                    </Tabs>
                                )
                        }
                    </Stack>

                    {/*  Data  */}
                    <DataTable
                        sorter={handleSort}
                        columns={columns.filter(c => c !== null)}
                        rows={rows.filter((r) => {
                            switch (tab) {
                                case 1:
                                    return r.approver_status === null ? false : r.approver_status.toString().toLowerCase().trim() === "pending approval";
                                case 2:
                                    return r.approver_status === null ? true : r.approver_status.toString().toLowerCase().trim() === "approved";
                                case 3:
                                    return r.status?.toString().toLowerCase().trim() !== "pending";
                                case 4:
                                    return r.approver_status === null ? false : r.approver_status.toString().toLowerCase().trim() === "rejected";
                                default:
                                    return true
                            }
                        })}
                    />

                </Stack>
            </Card>
        </Stack>
    )
}
