import React, {useState} from "react";
import {Box, Button, IconButton, InputAdornment, Link, Stack, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import {Visibility, VisibilityOff} from "@mui/icons-material";

// import zoneLanding from "../../static/img/auth/zone_landing.svg";

import {Form} from "../../components/Form";
import {InputTextField} from "../../components/InputTextField";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet-async";


const LoginImage = styled('img')(({theme}) => ({
    maxWidth: '45vw'
}));


const StyledLink = styled(Link)(({theme}) => ({
    color: theme.palette.text.primary,
    textDecorationColor: theme.palette.text.primary
}));


export default function Login() {

    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault()

        if (email && password && e.target.reportValidity())
            axios.post(`${process.env.REACT_APP_API_URL}/api/users/login`, {email, password}).then((res) => {
                if (res.status === 201) {
                    navigate('/dashboard');
                    console.log("Login Successful");
                }
            }).catch((err) => {
                if (err.response?.status === 401) {
                    window.alert("Invalid email address or password");
                    document.querySelector(['input[name=password]'])?.focus();
                } else if (err.response?.status === 404) {
                    window.alert("No such user found, please sign up first.")
                    document.querySelector(['input[name=email]'])?.focus();
                }
                console.log('Login Error', err);
            });
    }

    return (
        <>
            <Helmet>
                <title>Login | Nimbey</title>
            </Helmet>

            <Stack flexDirection={'row'} flex={1}
                   sx={{height: '100%', mx: {lg: 4}, alignItems: 'center', justifyContent: 'center'}}>

                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: {xs: 'center', lg: 'center'},
                    flex: 1,
                    height: '100%'
                }}>
                    <Form onSubmit={handleSubmit}>
                        <Stack spacing={2} sx={{mb: 5}}>
                            <Typography component={'span'} fontSize={24} fontWeight={700}>Sign in to Nimbey</Typography>
                            <Typography component={'span'} fontSize={14}>
                                New user? <Link sx={{textDecoration: 'none', fontWeight: 600}} href={"/register"}>Create
                                an account</Link>
                            </Typography>
                        </Stack>
                        <Stack spacing={2.5} sx={{width: {xs: '100%', md: '360px'}}}>

                            <InputTextField onChange={(e) => setEmail(e.target.value)} sx={{mb: 1}}
                                            defaultValue={email}
                                            type={"email"} name={"email"} required={true} label={"Email"}/>
                            <InputTextField onChange={(e) => setPassword(e.target.value)} sx={{mt: 1}}
                                            defaultValue={password}
                                            type={showPassword ? "text" : "password"} name={"password"} required={true}
                                            label={"Password"}
                                            InputProps={{
                                                endAdornment:
                                                    <InputAdornment position={"end"}>
                                                        <IconButton onClick={() => setShowPassword(!showPassword)}>
                                                            {showPassword ? <Visibility/> : <VisibilityOff/>}
                                                        </IconButton>
                                                    </InputAdornment>
                                            }}
                            />
                            <Box width={"100%"} sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                <StyledLink href={"/forgot-password"}>Forgot Password?</StyledLink>
                            </Box>
                            <Button variant={'contained'} type={"submit"}
                                    sx={(theme) => ({
                                        textTransform: 'none',
                                        color: '#FFF',
                                        backgroundColor: theme.palette.text.primary,
                                        borderRadius: 2,
                                        px: 4,
                                        py: 1.5,
                                        fontWeight: 600
                                    })}
                            >
                                Login
                            </Button>

                        </Stack>
                    </Form>
                </Box>

                {/*<Box*/}
                {/*    sx={{display: {xs: 'none', md: 'flex'}, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flex: {md: 1, lg: 2}, height: '100%'}}>*/}
                {/*    <LoginImage src={zoneLanding} />*/}
                {/*</Box>*/}

            </Stack>
        </>
    )
}