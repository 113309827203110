import React, {useState} from "react";
import {Box, Button, IconButton, InputAdornment, Link, Stack, Typography} from "@mui/material";
import {Form} from "../../components/Form";
import {InputTextField} from "../../components/InputTextField";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {styled} from "@mui/material/styles";
import {Helmet} from "react-helmet-async";


const StyledLink = styled(Link)(({theme}) => ({
    color: theme.palette.text.primary,
    textDecorationColor: theme.palette.text.primary
}));

export default function SignUp() {

    const navigate = useNavigate();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [role, setRole] = useState("");
    const [department, setDepartment] = useState("");
    const [company, setCompany] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (firstName && lastName && email && password && e.target.reportValidity())

            axios.post(`${process.env.REACT_APP_API_URL}/api/users/signup`, {
                fullName: `${firstName} ${lastName}`, companyName: company, department, role,
                email, password
            }).then((res) => {
                if (res.status === 201) {
                    console.log("Sign Up Successful");
                    navigate(`/activate`, {state: {email}});

                }
            }).catch((err) => {
                if (err.response?.status === 409) {
                    window.alert("Email already taken");
                    document.querySelector("input[name=email]")?.focus();
                } else {
                    window.alert("Something went wrong");
                }
                console.log('Sign Up Error', err?.response.data);
            });
    }


    return (
        <>
            <Helmet>
                <title>Signup | Nimbey</title>
            </Helmet>

            <Stack flexDirection={'row'} flex={1}
                   sx={{width: '100%', height: '100%', mx: {lg: 4}, alignItems: 'center', justifyContent: 'center'}}>

                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: {xs: 'center', lg: 'center'},
                    flex: 1,
                    height: '100%'
                }}>
                    <Form onSubmit={handleSubmit}>
                        <Stack spacing={2} sx={{mb: 5}}>
                            <Typography component={'span'} fontSize={24} fontWeight={700}>Sign up</Typography>
                            <Typography component={'span'} fontSize={14}>
                                Already have an account? <Link sx={{textDecoration: 'none', fontWeight: 600}}
                                                               href={"/login"}>Login</Link>
                            </Typography>
                        </Stack>

                        <Stack gap={2} flexDirection={"row"} flexWrap={'wrap'} alignItems={"center"}>
                            <Stack spacing={2.5} sx={{width: {xs: '100%', md: '300px'}}}>
                                <InputTextField onChange={(e) => setFirstName(e.target.value)}
                                                defaultValue={firstName}
                                                type={"text"} name={"firstName"} required={true} label={"First name"}/>


                                <InputTextField onChange={(e) => setEmail(e.target.value)} sx={{mb: 1}}
                                                defaultValue={email}
                                                type={"email"} name={"email"} required={true} label={"Email address"}/>

                                <InputTextField onChange={(e) => setPassword(e.target.value)} sx={{mt: 1}}
                                                defaultValue={password}
                                                type={showPassword ? "text" : "password"} name={"password"}
                                                required={true} label={"Password"}
                                                InputProps={{
                                                    endAdornment:
                                                        <InputAdornment position={"end"}>
                                                            <IconButton onClick={() => setShowPassword(!showPassword)}>
                                                                {showPassword ? <Visibility/> : <VisibilityOff/>}
                                                            </IconButton>
                                                        </InputAdornment>
                                                }}
                                />

                            </Stack>

                            <Stack spacing={2.5} sx={{width: {xs: '100%', md: '300px'}}}>

                                <InputTextField onChange={(e) => setLastName(e.target.value)}
                                                defaultValue={lastName}
                                                type={"text"} name={"lastName"} required={true} label={"Last name"}/>

                                <Stack gap={2} flexDirection={'row'}>
                                    <InputTextField onChange={(e) => setRole(e.target.value)}
                                                    defaultValue={role} sx={{flex: 1}}
                                                    type={"text"} name={"role"} required={true} label={"Role"}/>

                                    <InputTextField onChange={(e) => setDepartment(e.target.value)}
                                                    defaultValue={department} sx={{flex: 1}}
                                                    type={"text"} name={"department"} required={true}
                                                    label={"Department"}/>

                                </Stack>

                                <InputTextField onChange={(e) => setCompany(e.target.value)} sx={{mb: 1}}
                                                defaultValue={company}
                                                type={"text"} name={"company"} required={true} label={"Company"}/>
                            </Stack>
                        </Stack>
                        <Stack spacing={2.5} sx={{mt: 2.5}}>
                            <Button variant={'contained'} type={"submit"}
                                    sx={(theme) => ({
                                        textTransform: 'none',
                                        color: '#FFF',
                                        backgroundColor: theme.palette.text.primary,
                                        borderRadius: 2,
                                        px: 4,
                                        py: 1.5,
                                        fontWeight: 600
                                    })}
                            >
                                Create Account
                            </Button>

                            <Typography component={'span'} fontSize={13} sx={(theme) => ({
                                color: theme.palette.text.secondary,
                                textAlign: 'center',
                                width: '100%'
                            })}>
                                By signing up, I agree to <StyledLink href={"#"}>Terms of Use</StyledLink>
                                <br/>and <StyledLink href={"#"}>Privacy Policy</StyledLink>.
                            </Typography>
                        </Stack>
                    </Form>
                </Box>

                {/* Image */}
                {/*<Box*/}
                {/*    sx={{display: {xs: 'none', md: 'flex'}, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flex: {md: 1, lg: 2}, height: '100%'}}>*/}
                {/**/}
                {/*</Box>*/}

            </Stack>

        </>
    )
}