import React, {useEffect, useState} from "react";
import {SearchProduct} from "./Search";
import {useParams} from "react-router-dom";
import axios from "axios";
import {CircularProgress, Stack, Typography} from "@mui/material";
import Sorter from "../../components/Sorter";
import sorter from "../../utils/sorter";

export default function Products() {

    const [res, setRes] = useState([]);
    const {query} = useParams();
    const [loader, setLoader] = useState(false);
    const columns = [
        {id: 'unit_price', name: "Unit Price", value: (props) => props.sub},
        {id: 'product_name', name: "Product Name", value: (props) => props.name},
    ];

    useEffect(() => {
        if(loader) setLoader(false);
        axios.post(`${process.env.REACT_APP_API_URL}/api/search`, {term: ""}).then((r) => {
            if(r.status === 200) {
                setLoader(true);
                setRes(r.data.map((prod) => (
                    {id: prod.product_id, img: prod.product_image, name: prod.product_name, sub: `${prod.unit_price}`, sub2: `Delivery by ${prod.est_date}`}
                )));

            }
        }).catch((err) => console.log(`Error searching: ${err}`));
    }, [query]);

    const handleSort = (query) => {
        sorter(columns, res, query, setRes)
    }

    const handleClose = (e) => {
        if (e.target.ariaDescription)
            handleSort(e.target.ariaDescription);
    };

    return (
        <>
            <Stack spacing={3} sx={{width: "100%", height: "100%", p: {xs: 0, md: 2}}}>

                <Stack flexDirection={"row"} sx={{width: "100%"}} justifyContent={"space-between"}
                       alignItems={'center'}>
                    <Typography fontWeight={700} fontSize={20}>Products</Typography>

                    <Sorter handleCloseSuper={handleClose} columns={columns}/>

                </Stack>

                <Stack sx={{width: "100%"}} flex={1}>
                    {
                        loader ?
                            res.map((r, i) => <SearchProduct key={i} {...r} />)
                            :
                            <CircularProgress sx={{m: 'auto'}} />

                    }
                </Stack>

            </Stack>
        </>
    )
}