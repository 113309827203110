import React, {useState} from "react";
import {Button, Link, Stack, Typography} from "@mui/material";
import {Form} from "../../components/Form";
import ICEmailSent from "../../static/img/ic-email-sent.svg";
import {ArrowBackIos} from "@mui/icons-material";
import {InputTextField} from "../../components/InputTextField";
import {useLocation, useNavigate} from "react-router-dom";
import axios from "axios";
import sendOTP from "../../utils/sendOTP";
import {Helmet} from "react-helmet-async";



const verifyUser = async (payload) => {
    if(payload.email && payload.otp) {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/users/verify`, payload);
            console.log(response);
            return response.status === 200 && response.data.valid
        } catch (e) {
            console.log(e);
        }
    }
}


export default function Activate() {

    const location = useLocation();
    const navigate = useNavigate();

    const [email, setEmail] = useState(location.state && location.state.email ? location.state.email : "");
    const [code, setCode] = useState(Array(4).fill(null));

    const handleSubmit = (evt) => {
        evt.preventDefault();
        if (email && !code.includes(null) && evt.target.reportValidity()) {
            verifyUser({email, otp: code.join("")}).then((res) => {
                window.alert(`Account activation ${res ? 'successful' : 'failed'}!`);
                if(res)
                    navigate('/login');
                else {
                    document.querySelector('input[name=code-0]').focus();
                }
            });
        }
    }

    const handleResendOTP = () => {
        if(email) {
            sendOTP(email).then((res) => {
                if(res) {
                    window.alert("Code sent successfully!");
                    document.querySelector('[name=code-0]')?.focus();
                } else window.alert("Code request failed!");
            }).catch((e) => console.log("Error sending code: ", e));
        } else {
            document.querySelector('input[name=email]')?.reportValidity();
        }
    }

    const handleCode = (evt) => {
        const num = Number.parseInt(evt.target.name.split("-").pop());
        const value = Number.parseInt(evt.target.value);
        if (isNaN(num) || isNaN(value)) return false;
        let codes = [...code];
        codes[num] = value;
        setCode(codes);
        if (num < 4) {
            document.querySelector(`[name=code-${num + 1}]`)?.focus();
        }
    }


    return (
        <>
            <Helmet>
                <title>Activate Account | Nimbey</title>
            </Helmet>

            <Stack flex={1} alignItems={'center'} justifyContent={"center"} sx={{width: '100%', height: '100%'}}>
                <Form style={{maxWidth: '748px'}} onSubmit={handleSubmit}>

                    <Stack sx={{
                        my: 5, mx: 3, flexDirection: {
                            xs: 'column', lg: 'row'
                        }
                    }} gap={3} alignItems={'center'}>

                        <Stack flexDirection={"column"} alignItems={'center'} justifyContent={"center"} flex={1}>


                            <img src={ICEmailSent} alt={"email sent"}/>

                            <Stack spacing={1} sx={{my: 2}} flexDirection={"column"} alignItems={'center'}
                                   justifyContent={"center"}>
                                <Typography component={'h4'} textAlign={'center'} fontSize={32} fontWeight={700}>Request
                                    sent successfully!</Typography>
                                <Typography component={'p'} textAlign={'center'} fontSize={14}>
                                    We've sent a 6-digit confirmation email to your email. Please enter the code in
                                    below box to verify your email.
                                </Typography>
                            </Stack>


                        </Stack>
                        <Stack spacing={3} flexDirection={"column"} alignItems={'center'} justifyContent={"center"}
                               flex={1}>

                            <InputTextField onChange={(e) => setEmail(e.target.value)} sx={{mb: 1, width: '100%'}}
                                            defaultValue={email}
                                            type={"email"} name={"email"} required={true} label={"Email"}/>

                            <Stack gap={2} flexDirection={"row"} alignItems={'center'} justifyContent={"center"}>
                                {
                                    Array(4).fill(0).map(
                                        (_, i) => {
                                            return <InputTextField key={i.toString()} onChange={handleCode}
                                                                   placeholder={"-"}
                                                                   InputProps={{
                                                                       inputProps: {
                                                                           pattern: "[0-9]{1}",
                                                                           maxLength: 1,
                                                                           defaultValue: code[i]
                                                                       }
                                                                   }}
                                                                   type={"text"} maxLength={1} name={`code-${i}`}
                                                                   required={true}/>
                                        }
                                    )
                                }
                            </Stack>


                            <Button variant={'contained'} type={"submit"}
                                    sx={(theme) => ({
                                        textTransform: 'none',
                                        color: '#FFF',
                                        width: '100%',
                                        backgroundColor: theme.palette.text.primary,
                                        borderRadius: 2,
                                        px: 4,
                                        py: 1.5,
                                        fontWeight: 600
                                    })}
                            >
                                Activate account
                            </Button>

                            <Typography component={'span'} fontSize={14}>
                                Don't have a code? <Link sx={{cursor: 'pointer', textDecoration: 'none', fontWeight: 600}}
                                                         onClick={handleResendOTP}>Resend code</Link>
                            </Typography>

                            <Link href={"/login"} sx={(theme) => ({
                                color: theme.palette.text.primary, textDecoration: 'none', fontWeight: 'bold',
                                display: 'flex', alignItems: 'center'
                            })}>
                                <ArrowBackIos sx={{fontSize: '16px'}}/> Return to sign in
                            </Link>


                        </Stack>


                    </Stack>

                </Form>
            </Stack>
        </>
    )
}