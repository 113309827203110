import React from "react";
import {Helmet} from "react-helmet-async";
import {Outlet} from "react-router-dom";

// Themes
import {ThemeProvider} from "@mui/material";
import { theme } from "./theme";
import Styles from "./theme/globalStyles";


export default function App() {
    return (
        <>
            <Helmet>
                <title>Nimbey</title>
            </Helmet>

            <ThemeProvider theme={theme}>
                <Styles />      {/*  Global CSS  */}
                <Outlet />
            </ThemeProvider>
        </>
    )
}