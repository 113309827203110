import {Link, Stack} from "@mui/material";
import React from "react";
import {styled} from "@mui/material/styles";

export const Card = ({ children, props, styleProps={} }) => {
    const style = {
        flex: 1,
        width: '100%',
        borderRadius: 2,
        p: 2,
        border: 1,
        borderColor: '#DFE3E8'
    };
    Object.keys(styleProps).forEach((k) => style[k] = styleProps[k]);

    return <Stack height={{xs: 'fit-content', md: '100%'}} sx={style} {...props}>
        {children}
    </Stack>
}

export const CardLink = styled(Link)(({theme}) => ({
    textDecoration: 'none',
    color: '#8E33FF',
}));