import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import "@fontsource/public-sans/400.css";
import "@fontsource/public-sans/500.css";
import "@fontsource/public-sans/600.css";
import "@fontsource/public-sans/700.css";

import reportWebVitals from './reportWebVitals';

// Router
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {HelmetProvider} from "react-helmet-async";
import AuthenticationLayout from "./layouts/AuthenticationLayout";
import Login from "./views/auth/Login";
import SignUp from "./views/auth/SignUp";
import ForgotPassword from "./views/auth/ForgotPassword";

import axios from "axios";
import ResetPassword from "./views/auth/ResetPassword";
import ResetSuccess from "./views/auth/ResetSuccess";
import Activate from "./views/auth/Activate";
import DashboardLayout from "./layouts/DashboardLayout";
import DashboardHome from "./views/dashboard/DashboardHome";
import Search from "./views/dashboard/Search";
import ContractUpload from "./views/dashboard/ContractUpload";
import ProductDetails from "./views/dashboard/ProductDetails";
import Products from "./views/dashboard/Products";
import OrderDetails from "./views/dashboard/OrderDetails";
import AdminView from "./views/dashboard/AdminView";
import OrderRequest from "./views/dashboard/OrderRequest";

axios.defaults.withCredentials = true;

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,   // Basic App
        children: [
            // Routes to Authentication
            {
                path: "",
                element: <AuthenticationLayout />,     // Authentication Layout
                children: [
                    {
                        path: "/login",
                        element: <Login />      // Login Component
                    },
                    {
                        path: "/register",
                        element: <SignUp />      // Signup Component
                    },
                    {
                        path: "/forgot-password",
                        element: <ForgotPassword />      // Forgot Password Component
                    },
                    {
                        path: "/reset-password",
                        element: <ResetPassword />,      // Reset Password Component
                    },
                    {
                        path: "/reset-success",
                        element: <ResetSuccess />       // On successful reset password
                    },
                    {
                        path: "/activate",
                        element: <Activate />              // to activate acc with OTP
                    }
                ]
            },

            // Routes to Dashboard
            {
                path: "/dashboard",
                element: <DashboardLayout />,
                children: [
                    {
                        path: "/dashboard",
                        element: <DashboardHome />      // Home page of Dashboard
                    },
                    {
                        path: "/dashboard/search/:query",
                        element: <Search />         // Search Page
                    },
                    {
                        path: "/dashboard/contract-upload",
                        element: <ContractUpload />      // Contract Upload Page
                    },
                    {
                        path: "/dashboard/products",
                        element: <Products />    // Product Listing
                    },
                    {
                        path: "/dashboard/products/:productID",
                        element: <ProductDetails />    // Product Details Page and Ordering New Items
                    },
                    {
                        path: "/dashboard/orders/:orderID",
                        element: <OrderDetails />       // user orders
                    },
                    {
                        path: "/dashboard/admin/orders",
                        element: <AdminView />      // page to show order history to the admin of the org
                    },
                    {
                        path: "/dashboard/order-request/:orderID",
                        element: <OrderRequest />           // route from slack, for approving orders
                    }
                ]
            }
        ]
    }
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <HelmetProvider>
          <RouterProvider router={router} />
      </HelmetProvider>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
