import React from "react";
import {Button, Stack, Typography} from "@mui/material";
import stackImage from "../../static/img/stack.svg";
import {Form} from "../../components/Form";
import {Helmet} from "react-helmet-async";

export default function ResetSuccess() {

    return (
        <>
            <Helmet>
                <title>Reset Success | Nimbey</title>
            </Helmet>

            <Stack flexDirection={"column"} flex={1} spacing={5} alignItems={"center"} justifyContent={"center"} sx={{width: "100%", height: "100%"}}>
                <Form sx={{py: 5, display: 'flex', flexDirection: "column", alignItems: "center"}}
                      style={{maxWidth: '780px'}}>

                    <img src={stackImage} alt={"reset-success"} />

                    <Stack sx={{my: 3}} flexDirection={"column"} alignItems={"center"} justifyContent={"center"} >

                        <Typography sx={{my: 1}} component={"h3"} fontSize={32} fontWeight={700} textAlign={'center'}>Password successfully changed!</Typography>

                        <Typography component={"span"} fontSize={14} textAlign={'center'}>
                            You can login using the button below.
                        </Typography>

                    </Stack>

                    <Button variant={'contained'} type={"button"} onClick={() => window.location.href = "/login"}
                            sx={(theme) => ({textTransform:'none', color: '#FFF', width: '50%', mt: 5,
                                backgroundColor: theme.palette.text.primary, borderRadius: 2, px: 4, py: 1.5, fontWeight: 600})}
                    >
                        Back to Login
                    </Button>
                </Form>

            </Stack>
        </>
    )
}