import React, {useState} from "react";
import {Form} from "../../components/Form";
import {Link, Button, Stack, Typography} from "@mui/material";
import icPassword from "../../static/img/ic-password.svg";
import {InputTextField} from "../../components/InputTextField";
import {
    ArrowBackIos,
    ArrowForwardIos
} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import sendOTP from "../../utils/sendOTP";
import {Helmet} from "react-helmet-async";


export default function ForgotPassword() {

    const navigate = useNavigate();
    const [email, setEmail] = useState("");

    const handleSubmit = (evt) => {
        evt.preventDefault();
        if(email && evt.target.reportValidity())
            sendOTP(email).then((res) => {
                if(res)
                    navigate("/reset-password", {state: {email}});
                else
                    window.alert("Request Failed");
            }).catch((e) => {
                console.log("Error sending request ", e);
            })
    }

    return (
        <>
            <Helmet>
                <title>Forgot Password | Nimbey</title>
            </Helmet>

            <Stack flexDirection={'row'} flex={1}
                   sx={{width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                <Form sx={{py: 5, px: 3}} style={{maxWidth: '420px'}} onSubmit={handleSubmit}>
                    <Stack spacing={5} alignItems={'center'}>
                        <img
                            onClick={() => navigate("/reset-password", {state: {email: email}})}
                            src={icPassword} alt={"password-lock"} width={96}/>

                        <Stack alignItems={'center'}>
                            <Typography component={"span"} fontSize={'32px'} fontWeight={700} sx={{textAlign: 'center'}}>Forgot
                                your password?</Typography>
                            <Typography component={"span"} fontSize={'14px'} sx={(theme) => ({
                                mt: 1,
                                width: '100%',
                                textAlign: 'center',
                                display: 'block',
                                color: theme.palette.text.secondary, lineBreak: 'anywhere'
                            })}>
                                Please enter the email address associated with your account, and we'll email you a link to
                                reset your password.
                            </Typography>
                        </Stack>

                        <Stack spacing={3} alignItems={'center'} sx={{width: '100%'}}>

                            <InputTextField onChange={(e) => setEmail(e.target.value)} sx={{mb: 1, width: '100%'}}
                                            defaultValue={email}
                                            type={"email"} name={"email"} required={true} label={"Email address"}/>

                            <Button variant={'contained'} type={"submit"}
                                    sx={(theme) =>
                                        ({
                                            textTransform:'none', color: '#FFF',
                                            backgroundColor: theme.palette.text.primary, borderRadius: 2, px: 2,
                                            py: 1.5, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'
                                        })}
                            >
                                <Typography fontSize={14} component={'span'} sx={(theme) => ({color: theme.palette.background.paper, fontWeight: 'bold'})}>
                                    Send Request
                                </Typography>
                                <ArrowForwardIos sx={{fill: '#FFF'}}/>
                            </Button>

                            <Link href={"/login"} sx={(theme) => ({color: theme.palette.text.primary, textDecoration: 'none', fontWeight: 'bold',
                                display: 'flex', alignItems: 'center'})}>
                                <ArrowBackIos sx={{fontSize: '16px'}} /> Return to sign in
                            </Link>

                        </Stack>
                    </Stack>


                </Form>
            </Stack>
        </>
    )
}