import {createTheme} from "@mui/material";
import {palette} from "./pallete";

export const PRIMARY_FONT = "Public Sans, sans-serif";


export const theme = createTheme({
    typography: {
        fontFamily: PRIMARY_FONT,
    },
    palette: palette,
    components: {
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fill: palette.text.secondary
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: '14px'
                },
                asterisk: {
                    display: "none"
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: palette.text.disabled
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                    borderColor: palette.text.disabled
                },
                input: {
                    padding: '16px 14px',
                    "&[type=tel]": {
                        textAlign: 'center',
                    }
                },
            }
        }
    }
});