import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {CircularProgress, IconButton, Link, Stack, Typography} from "@mui/material";
import {LocalShippingOutlined, MoreVert} from "@mui/icons-material";
import axios from "axios";
import Sorter from "../../components/Sorter";
import sorter from "../../utils/sorter";


export const SearchProduct = ({id, img, name, sub, sub2}) => <Stack
    sx={{position: 'relative', py: 2.5, px: {xs: 0, md: 2.5}, width: '100%', borderBottom: '1px solid #DFE3E8'}}
    flexDirection={"row"}>
    <img src={img} alt={"CD"} height={"48px"} width={"48px"} style={{objectFit: "cover"}}/>
    <Stack sx={{px: 2, width: '100%'}}>
        <Link sx={{textDecoration: 'none'}} href={`/dashboard/products/${id}`}>
            <Typography fontWeight={600} sx={{fontSize: {xs: 12, md: 16}}}>{name}</Typography>
        </Link>
        <Typography sx={(theme) => ({my: 0.5, color: theme.palette.text.disabled})} fontSize={12}>Price: ${sub}</Typography>
        <Typography sx={{color: '#00A76F', display: 'flex', alignItems: 'center'}} fontSize={12}><LocalShippingOutlined
            sx={{fill: '#00A76F', mr: .5, fontSize: '14px'}}/>{sub2}</Typography>
    </Stack>
    <IconButton sx={{position: {md: "absolute"}, right: 0, m: {md: 1}, p: {xs: 0, md: 1}, backgroundColor: '#FFF'}}>
        <MoreVert/>
    </IconButton>
</Stack>

export default function Search() {

    const [res, setRes] = useState([]);
    const {query} = useParams();
    const [loader, setLoader] = useState(false);
    const columns = [
        {id: 'unit_price', name: "Unit Price", value: (props) => props.sub},
        {id: 'product_name', name: "Product Name", value: (props) => props.name},
    ];

    useEffect(() => {
        if (loader) setLoader(false);
        axios.post(`${process.env.REACT_APP_API_URL}/api/search`, {term: query}).then((r) => {
            if (r.status === 200) {
                setLoader(true);
                setRes(r.data.map((prod) => (
                    {
                        id: prod.product_id,
                        img: prod.product_image,
                        name: prod.product_name,
                        sub: `${prod.unit_price}`,
                        sub2: `Delivery by ${prod.est_date}`
                    }
                )));

            }
        }).catch((err) => console.log(`Error searching: ${err}`));
    }, [query]);

    const handleSort = (query) => {
        sorter(columns, res, query, setRes)
    }

    const handleClose = (e) => {
        if (e.target.ariaDescription)
            handleSort(e.target.ariaDescription);
    };

    return (
        <>
            <Stack spacing={3} sx={{width: "100%", height: "100%", p: {xs: 0, md: 2}}}>

                <Stack flexDirection={"row"} sx={{width: "100%"}} justifyContent={"space-between"}
                       alignItems={'center'}>
                    <Typography fontWeight={600} fontSize={20}>Search Results "{query}"</Typography>

                    <Sorter handleCloseSuper={handleClose} columns={columns}/>

                </Stack>

                <Stack sx={{width: "100%"}} flex={1}>
                    {
                        loader ?
                            res.map((r, i) => <SearchProduct key={i} {...r} />)
                            :
                            <CircularProgress sx={{m: 'auto'}}/>

                    }
                </Stack>

            </Stack>
        </>
    )
}