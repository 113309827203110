import React, {useState} from "react";
import {Button, IconButton, InputAdornment, Link, Stack, Typography} from "@mui/material";
import {Form} from "../../components/Form";
import ICEmailSent from "../../static/img/ic-email-sent.svg";
import {ArrowBackIos, Visibility, VisibilityOff} from "@mui/icons-material";
import {InputTextField} from "../../components/InputTextField";
import {useLocation, useNavigate} from "react-router-dom";
import axios from "axios";
import {Helmet} from "react-helmet-async";


const submitNewPassword = async (payload) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/users/verifyOTPForgotPassword`, payload);
        return (response.status === 200 && response.data.valid)
    } catch (e) {
        console.log("Error resetting password ", e);
        throw e;
    }

}

export default function ResetPassword() {

    const location = useLocation();
    const navigate = useNavigate();

    const [email, setEmail] = useState(location?.state?.email || "");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [code, setCode] = useState(Array(4).fill(null));

    const handleSubmit = (evt) => {
        evt.preventDefault();
        if (email && password && !code.includes(null) && evt.target.reportValidity())
            if (password === confirmPassword) {
                submitNewPassword({email, newPassword: password, otp: code.join("")}).then((res) => {
                    window.alert(`Password reset ${res ? 'successful' : 'failed'}!`);
                    if(res) {
                        navigate('/reset-success');
                    }
                });
            } else {
                window.alert("Password don't match");
                document.querySelector('input[name=confirmPassword]').focus();
            }
    }

    const handleCode = (evt) => {
        const num = Number.parseInt(evt.target.name.split("-").pop());
        const value = Number.parseInt(evt.target.value);
        if(isNaN(num) || isNaN(value)) return false;
        let codes = [...code]; codes[num] = value;
        console.log(codes.join(""));
        setCode(codes);
        if(num < 4) {
            document.querySelector(`[name=code-${num + 1}]`)?.focus();
        }
    }

    return (
        <>
            <Helmet>
                <title>Reset Password | Nimbey</title>
            </Helmet>

            <Stack alignItems={'center'} flex={1} justifyContent={"center"} sx={{width: '100%', height: '100%'}}>
                <Form style={{maxWidth: '748px'}} onSubmit={handleSubmit}>

                    <Stack sx={{my: 5, mx: 3, flexDirection: {
                        xs: 'column', lg: 'row'
                        }}} gap={3} alignItems={'center'}>

                        <Stack flexDirection={"column"} alignItems={'center'} justifyContent={"center"} flex={1}>


                            <img src={ICEmailSent} alt={"email sent"}/>

                            <Stack spacing={1} sx={{my: 2}} flexDirection={"column"} alignItems={'center'}
                                   justifyContent={"center"}>
                                <Typography component={'h4'} textAlign={'center'} fontSize={32} fontWeight={700}>Request
                                    sent successfully!</Typography>
                                <Typography component={'p'} textAlign={'center'} fontSize={14}>
                                    We've sent a 6-digit confirmation email to your email. Please enter the code in
                                    below box to verify your email.
                                </Typography>
                            </Stack>


                        </Stack>
                        <Stack spacing={3} flexDirection={"column"} alignItems={'center'} justifyContent={"center"}
                               flex={1}>

                            <InputTextField onChange={(e) => setEmail(e.target.value)} sx={{mb: 1, width: '100%'}}
                                            defaultValue={email}
                                            type={"email"} name={"email"} required={true} label={"Email"}/>

                            <Stack gap={2} flexDirection={"row"} alignItems={'center'} justifyContent={"center"}>
                                {
                                    Array(4).fill(0).map(
                                        (_, i) => {
                                            return <InputTextField key={i.toString()} onChange={handleCode} placeholder={"-"}
                                                              InputProps={{inputProps: {pattern: "[0-9]{1}", maxLength: 1}}}
                                                              type={"text"} maxLength={1} name={`code-${i}`} required={true}/>
                                        }
                                    )
                                }
                            </Stack>


                            <InputTextField onChange={(e) => setPassword(e.target.value)} sx={{mt: 1, width: '100%'}}
                                            defaultValue={password}
                                            type={showPassword ? "text" : "password"} name={"password"} required={true}
                                            label={"Password"}
                                            InputProps={{
                                                inputProps: {minLength: 8},
                                                endAdornment:
                                                    <InputAdornment position={"end"}>
                                                        <IconButton onClick={() => setShowPassword(!showPassword)}>
                                                            {showPassword ? <Visibility/> : <VisibilityOff/>}
                                                        </IconButton>
                                                    </InputAdornment>
                                            }}
                            />


                            <InputTextField onChange={(e) => setConfirmPassword(e.target.value)}
                                            sx={{mt: 1, width: '100%'}} InputProps={{inputProps: {minLength: 8}}}
                                            type={showPassword ? "text" : "password"} name={"confirmPassword"}
                                            required={true}
                                            label={"Confirm Password"}
                            />


                            <Button variant={'contained'} type={"submit"}
                                    sx={(theme) => ({
                                        textTransform: 'none',
                                        color: '#FFF',
                                        width: '100%',
                                        backgroundColor: theme.palette.text.primary,
                                        borderRadius: 2,
                                        px: 4,
                                        py: 1.5,
                                        fontWeight: 600
                                    })}
                            >
                                Update Password
                            </Button>

                            <Typography component={'span'} fontSize={14}>
                                Don't have a code? <Link sx={{textDecoration: 'none', fontWeight: 600}}
                                                         href={"/register"}>Resend code</Link>
                            </Typography>

                            <Link href={"/login"} sx={(theme) => ({
                                color: theme.palette.text.primary, textDecoration: 'none', fontWeight: 'bold',
                                display: 'flex', alignItems: 'center'
                            })}>
                                <ArrowBackIos sx={{fontSize: '16px'}}/> Return to sign in
                            </Link>


                        </Stack>


                    </Stack>

                </Form>
            </Stack>
        </>
    )
}