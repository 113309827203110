import React from "react";
import {IconButton, Link, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import {MoreVert} from "@mui/icons-material";
import {CardLink} from "./Card";
import FallbackImage from "../static/img/fallback.png";

export default function DataTable({columns, rows, sorter = (props) => {}, startCellProps, selector = false}) {

    return (
        <>
            <Table sx={{mt: 2, "& th, & td": {borderBottom: 0, whiteSpace: 'nowrap'}}}>

                <TableHead sx={(theme) => ({backgroundColor: theme.palette.background.neutral})}>
                    <TableRow key={'selector'}>
                        {
                            selector ?
                                <TableCell {...startCellProps}>
                                    <input name={'header'} type={'checkbox'}/>
                                </TableCell>
                                : <></>}
                        {
                            columns.map((col) =>
                                <TableCell key={col.id}
                                           sx={(theme) => ({"& span": {color: theme.palette.text.secondary}})}>
                                    <span style={{cursor: "pointer"}} onClick={() => sorter(col.id)}>{col.name}</span>
                                    {/*<ArrowDownwardOutlined />*/}
                                </TableCell>
                            )
                        }
                        <TableCell key={'options'}/>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {
                        rows.map((props) =>
                            <TableRow key={`order-${props.order_id}`} sx={{"& td": {fontSize: '12px'}}}>
                                {
                                    columns.map((c) => {
                                            const {img, icon, name, subtitle, head, chip} = c.render(props);
                                            return <TableCell key={`cell-${c.id}-order-${props.order_id}`}>
                                                <Stack gap={2} flexDirection={"row"}
                                                       sx={{height: 'fit-content', width: '100%'}}
                                                       alignItems={"center"}>
                                                    {
                                                        img ? <img src={img || FallbackImage} alt={name}
                                                                   style={{
                                                                       aspectRatio: 1,
                                                                       width: "48px",
                                                                       objectFit: 'cover'
                                                                   }}/>
                                                            : icon ? icon : <></>
                                                    }
                                                    <Stack>
                                                        {
                                                            head ?
                                                                <Link sx={{textDecoration: 'none'}} href={`/dashboard/orders/${props.order_id}`}>
                                                                    <Typography fontSize={14} fontWeight={600}>{head}</Typography></Link>
                                                                : <></>
                                                        }
                                                        {
                                                            name ? <Typography fontSize={12}>{name}</Typography>
                                                                : <></>
                                                        }
                                                        {
                                                            subtitle ? <Typography fontSize={12}
                                                                                   sx={(theme) => ({color: theme.palette.text.secondary})}>{subtitle}</Typography>
                                                                : <></>
                                                        }
                                                        {
                                                            chip ? <span style={
                                                                {
                                                                    fontWeight: 600,
                                                                    padding: "4px 6px", borderRadius: "8px",
                                                                    ...(chip === "Pending" ? {backgroundColor: "#FFAB0029", color: "#B76E00"} : chip === "Completed" ? {backgroundColor: "#118D57", color: "#22C55E29"} : {})
                                                                }
                                                            }>{chip}</span> : <></>
                                                        }

                                                    </Stack>
                                                </Stack>
                                            </TableCell>
                                        }
                                    )
                                }


                                <TableCell sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}>

                                    <CardLink
                                        sx={(theme) => ({color: theme.palette.text.info, fontWeight: 600})}
                                        href={`/dashboard/orders/${props.order_id}`}>View details</CardLink>
                                    <IconButton><MoreVert/></IconButton>

                                </TableCell>


                            </TableRow>
                        )
                    }
                </TableBody>

            </Table>
        </>
    )
}