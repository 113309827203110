import {Stack, Typography} from "@mui/material";
import {Circle} from "@mui/icons-material";
import DataTable from "../../components/DataTable";
import React, {useEffect, useState} from "react";
import {columnSet} from "../../components/UserOrders";
import axios from "axios";
import Sorter from "../../components/Sorter";
import sorter from "../../utils/sorter";
import {useNavigate, useOutletContext} from "react-router-dom";

export default function AdminView() {


    const [rows, setRows] = useState([]);
    const columns = columnSet(0);

    const {user} = useOutletContext();
    const navigate = useNavigate();

    const handleSort = (query) => {
        sorter(columns, rows, query, setRows)
    }

    const handleClose = (e) => {
        if(e.target.ariaDescription)
            handleSort(e.target.ariaDescription);
    }

    useEffect(() => {
        if(user && user.admin)
            axios.get(`${process.env.REACT_APP_API_URL}/api/admin-orders`).then((res) => {
            if(res.status === 200) {
                setRows(res.data);
            }
        }).catch((err) => {
            console.log(`Error fetching orders: ${err}`); window.alert(`couldn't fetching orders`);
        })
        else
            navigate("/dashboard");

    }, []);

    return (
        <>
            <Stack flex={1} spacing={2} sx={{width: '100%'}}>
                <Stack sx={{px: {lg: 1}}}>
                    <Typography fontSize={'24px'} fontWeight={700}>Admin Dashboard</Typography>
                    <Stack flexDirection={"row"} gap={1} alignItems={'center'}>
                        <Typography fontSize={'12px'} sx={{color: 'text.secondary'}}>Dashboard</Typography>
                        <Circle sx={{width: '4px'}} />
                        <Typography fontSize={'12px'} sx={{color: 'text.secondary'}}>Admin</Typography>
                        <Circle sx={{width: '4px'}} />
                        <Typography fontSize={'12px'} sx={{color: 'text.secondary'}}>Previous Orders</Typography>
                    </Stack>
                </Stack>
                <Stack sx={{width: '100%'}} alignItems={'flex-end'}>
                    <Sorter handleCloseSuper={handleClose} columns={columns.filter(c => c !== null)} />
                </Stack>
                <DataTable sorter={handleSort} columns={columns} rows={rows} />
            </Stack>
        </>
    )
}